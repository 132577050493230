<template>
  <div class="entryMaterials-container">
    <div class="waitLoading" v-show="skeleton">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="waiting" v-if="form.exa_status == 1">
      <div class="title_blod">
        <span class="span">审核中请耐心等待</span>
      </div>
      <div class="pic">
        <img class="img" src="@/assets/smartcat/waiting.png" alt="">
      </div>
      <div class="title">
        <span class="span">资料已提交成功,工作人员将在1-2个工作日反馈审核结果</span>
      </div>
    </div>
    <!-- 个人审核通过 -->
    <div class="waiting sh" v-if="(form.exa_status == 2 && form.exa_type == 1 )&& form.open_at==null">
      <div class="title_blod">
        <span class="span">审核通过，系统已交付</span>
      </div>
      <div class="pic">
        <img class="img" src="@/assets/smartcat/passSh.png" alt="">
      </div>
      <div class="btn">
        <a-button @click="openStore" class="confirm-btn">开通店铺</a-button>
      </div>
    </div>
    <!-- 个人店铺开通成功 -->
    <div class="pass sh" v-if="(form.exa_status == 2 && form.exa_type == 1) && form.open_at">
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">开通时间:</span>
          </div>
          <div class="addr">
            {{ form.open_at }}
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
              推荐使用扫描二维码访问
            </div>
          </div>
      </div>
    </div>
    </div>
    <!-- 企业审核通过 -->
    <div class="waiting pass sh" v-if="(form.exa_status == 2 && form.exa_type == 2) && form.fch_examine_contract == ''">
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">审核通过，系统已交付，等待签订合同:&nbsp;&nbsp; &nbsp;联系工作人员签订合同</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
              推荐使用扫描二维码访问
            </div>
          </div>
      </div>
    </div>
    </div>
    <!-- 企业合同通过 -->
    <div class="waiting pass sh" v-if="(form.exa_status == 2 && form.exa_type == 2) && (form.fch_examine_contract.length && form.open_at==null)">
      <!-- <div class="title_blod">
        <span class="span">合同已签订</span>
      </div>
      <div class="pic">
        <img class="img" src="@/assets/smartcat/passHt.png" alt="">
      </div>
      <div class="btn">
        <a-button @click="openStore" class="confirm-btn">开通店铺</a-button>
      </div> -->
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">合同签订成功</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
              推荐使用扫描二维码访问
            </div>
          </div>
      </div>
      <div class="btn">
        <a-button @click="openStore" class="confirm-btn">开通店铺</a-button>
      </div> 
    </div>
    </div>
    <!-- 企业开通店铺 -->
  <div class="waiting pass sh" v-if="(form.exa_status == 2 && form.exa_type == 2) && (form.fch_examine_contract.length && form.open_at)">
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
              推荐使用扫描二维码访问
            </div>
          </div>
      </div>
      <div class="addr-item">
          <div class="text">
            <span class="span">开通时间:{{ form.open_at }}</span>
          </div>
        </div>
    </div>
    </div>
    <!-- <div class="pass ht" v-if="form.exa_status == 4">
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
              推荐使用扫描二维码访问
            </div>
          </div>

        </div>
      </div>
    </div> -->
    <div v-if="form.exa_status == 3" class="fail sh">
      <div class="refusalReason">
        <div class="left">
          <span class="span">拒绝原因:</span>
        </div>
        <div class="right">
          <div class="span">{{ form.remake }}</div>
        </div>
      </div>
      <div class="question">
        <span class="span">如有疑问请联系客服咨询！</span>
      </div>
      <div class="title">
        <span class="submitted">
          已提交信息
        </span>
      </div>
      <div class="submittedForm">
        <div v-if="form_id == 1">
          <div class="formItem">
            <a-col :span="4">
              <div>主题类型:</div>
            </a-col>
            <a-col>
              <div>个人</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>本人国籍:</div>
            </a-col>
            <a-col>
              <div>{{ form.country.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>姓名:</div>
            </a-col>
            <a-col>
              <div>{{ form.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>手机号:</div>
            </a-col>
            <a-col>
              <div>{{ form.phone }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>身份证号:</div>
            </a-col>
            <a-col>
              <div>{{ form.id_card }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>联系方式:</div>
            </a-col>
            <a-col>
              <div>{{ form.contactShow }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>常用邮箱:</div>
            </a-col>
            <a-col>
              <div>{{ form.email }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col>
              <a-button class="btn" @click="re_edit(1)">重新编辑</a-button>
            </a-col>
          </div>
        </div>
        <div v-if="form_id == 2">
          <div class="formItem">
            <a-col :span="4">
              <div>主题类型:</div>
            </a-col>
            <a-col>
              <div>个人</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>本人国籍:</div>
            </a-col>
            <a-col>
              <div>{{ form.country.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <div class="applyInfo">a.申请人信息</div>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>姓名:</div>
            </a-col>
            <a-col>
              <div>{{ form.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>手机号:</div>
            </a-col>
            <a-col>
              <div>{{ form.phone }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>联系方式:</div>
            </a-col>
            <a-col>
              <div>{{ form.contactShow }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>常用邮箱:</div>
            </a-col>
            <a-col>
              <div>{{ form.email }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <div class="applyInfo">b.申请人信息</div>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>姓名:</div>
            </a-col>
            <a-col>
              <div>{{ form.p_cif_name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>手机号:</div>
            </a-col>
            <a-col>
              <div>{{ form.p_cif_phone }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>身份证号:</div>
            </a-col>
            <a-col>
              <div>{{ form.id_card }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>联系方式:</div>
            </a-col>
            <a-col>
              <div>{{ form.p_cif_contactShow }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>常用邮箱:</div>
            </a-col>
            <a-col>
              <div>{{ form.p_cif_email }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col>
              <button class="btn" @click="re_edit(2)">重新编辑</button>
            </a-col>
          </div>
        </div>
        <div v-if="form_id == 3">
          <div class="formItem">
            <a-col :span="4">
              <div>主题类型:</div>
            </a-col>
            <a-col>
              <div>公司</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="14">
              <div>是否需要SMARTCAT帮助代注册企业？</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col :span="14">
              <div>是 ，授权SMARTCAT代注册企业</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>姓名:</div>
            </a-col>
            <a-col>
              <div>{{ form.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>手机号:</div>
            </a-col>
            <a-col>
              <div>{{ form.phone }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>身份证号:</div>
            </a-col>
            <a-col>
              <div>{{ form.id_card }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>联系方式:</div>
            </a-col>
            <a-col>
              <div>{{ form.contactShow }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>常用邮箱:</div>
            </a-col>
            <a-col>
              <div>{{ form.email }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col>
              <a-button class="btn" @click="re_edit(3)">重新编辑</a-button>
            </a-col>
          </div>
        </div>
        <div v-if="form_id == 4">
          <div class="formItem">
            <a-col :span="4">
              <div>主题类型:</div>
            </a-col>
            <a-col>
              <div>公司</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="14">
              <div>是否需要SMARTCAT帮助代注册企业？</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col :span="14">
              <div>否 ，授权SMARTCAT代注册企业</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>公司地址:</div>
            </a-col>
            <a-col>
              <div>{{ form.c_name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>经营地址:</div>
            </a-col>
            <a-col>
              <div>{{ form.c_addr }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>统一社会信用代码:</div>
            </a-col>
            <a-col>
              <div>{{ form.c_code }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>营业执照:</div>
            </a-col>
            <a-col>
              <div class="businessLicense">
                <img class="img" :src="form.c_image" alt="">
              </div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>法人姓名:</div>
            </a-col>
            <a-col>
              <div>{{ form.name }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>法人手机号:</div>
            </a-col>
            <a-col>
              <div>{{ form.phone }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>联系方式:</div>
            </a-col>
            <a-col>
              <div>{{ form.contactShow }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
              <div>常用邮箱:</div>
            </a-col>
            <a-col>
              <div>{{ form.email }}</div>
            </a-col>
          </div>
          <div class="formItem">
            <a-col :span="4">
            </a-col>
            <a-col>
              <a-button class="btn" @click="re_edit(4)">重新编辑</a-button>
            </a-col>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="form.exa_status == 4" class="pass ht">
      <div class="Addr">
        <div class="addr-item">
          <div class="text">
            <span class="span">系统访问地址:</span>
          </div>
          <div class="addr">
            <a href='https://jiyun.smartcatplus.com/#/login' target="_blank">点击跳转访问网站</a>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具访问地址:</span>
          </div>
          <div class="addr">
            {{ form.fch_url }}
          </div>
          <div class="copyBtn">
            <button class="btn" @click="copy">点击复制</button>
          </div>
        </div>
        <div class="addr-item">
          <div class="text">
            <span class="span">集运工具:</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img  class="img" :src="this.$baseUrl.IMAGE_URL + form.qr_code" alt="">
            <div class="addr">
            推荐使用扫描二维码访问
          </div>
          </div>
        
        </div>
      </div>
    </div>
    <div v-if="form.exa_status == 5" class="fail ht">
      <div class="progressBar">
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/pass.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="Shallow">提交材料</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/pass.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="Shallow">审核通过</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/fail.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="Shallow">签订失败</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="circle">
            <div class="across">
            </div>
          </div>
          <div class="BarTitle">
            <span class="Shallow">交付系统</span>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="addr-item">
          <div class="text">
            <span class="span">合同签订失败，请前往小程序重新签订合同</span>
          </div>
        </div>
        <div class="addr-item">
          <div class="pic">
            <img class="img" src="https://beego-1252088954.cos.ap-hongkong.myqcloud.com/mini/sc_mini.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div v-if="form.exa_status == 6" class="waiting ht">
      <div class="progressBar">
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/pass.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="Shallow">提交材料</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/pass.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="Shallow">审核通过</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="status">
            <img class="statusImg" src="@/assets/smartcat/pass.png" alt="图标加载失败">
          </div>
          <div class="BarTitle">
            <span class="span">签订合同</span>
          </div>
          <div class="dash">
            <span v-for="index in 8" :key="index" class="dashItem"></span>
          </div>
        </div>
        <div class="Bar">
          <div class="circle">
            <div class="across">
            </div>
          </div>
          <div class="BarTitle">
            <span class="Shallow">交付系统</span>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="addr-item">
          <div class="pic">
        <img class="img" src="@/assets/smartcat/waiting.png" alt="">
      </div>
        </div>
          <div class="text">
            <span class="span">工作人员核验合同后将为您开通系统</span>
          </div>
      </div>
    </div> -->
    <div v-if="form.exa_status == 99" class="submit">
      <div class="entry-item">
        <h4 class="title">01. 请选择申请主体类型</h4>
        <div class="entryType">
          <a-row>
            <a-col :span="4" class="type">
              主体类型:
            </a-col>
            <a-col :span="12">
              <a-radio-group prop="radioValue" v-model="form.exa_type" name="radioGroup" :default-value="1">
                <a-radio :value="1" class="custom-radio">
                  个人
                </a-radio>
                <a-radio :value="2" class="custom-radio ml50">
                  公司
                </a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <div v-show="form.exa_type == 2" class="proposer-info">
            <h6 class="notice-title">注：“企业”在营业执照上的主体类型一般为：有限公司、有限责任公司等</h6>
            <div class="proposer-item">
              <a-row>
                <a-col class="type">
                  是否需要SMARTCAT帮助代注册企业？
                </a-col>
              </a-row>
              <div>
                <a-row>
                  <a-col :span="4">
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.c_reg" name="radioGroup" style="margin-top:20px">
                      <a-radio :style="radioStyle" :value="1" class="custom-radio">
                        是，授权SMARTCAT代注册企业
                      </a-radio>
                      <a-radio :style="radioStyle" :value="2" class="custom-radio">
                        否，我已拥有相关资质的企业
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="form.exa_type == 1" class="entry-item">
        <h4 class="title">02.请填写联系方式,&nbsp;&nbsp;以便工作人员联系您沟通相关事宜</h4>
        <div class="entryType">
          <div>
            <a-row>
              <a-col :span="4" class="type">
                本人国籍:
              </a-col>
              <a-col :span="6">
                <a-radio-group v-model="form.p_country_ids" @change="countryWay()" name="radioGroup" :default-value="1">
                  <a-radio :value="1" class="custom-radio">
                    中国
                  </a-radio>
                  <a-radio :value="2" class="custom-radio ml50">
                    其他
                  </a-radio>
                </a-radio-group>
              </a-col>
              <a-col :span="4" class="selStyle">
                <a-select v-model="form.p_country_id" :disabled="form.p_country_ids == 1" show-search placeholder="选择国家"
                  class="rounded-select">
                  <a-icon style="margin-top:-4px" slot="suffixIcon" type="caret-down" :style="{ fontSize: '16px' }" />
                  <a-select-option v-for="item in country" :key="item.id" :label="item.name" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-show="form.p_country_ids == 1">
            <div class="proposer-info">
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    姓名:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.name" class="rounded-input" placeholder="请填写您的姓名" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    手机号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.phone" class="rounded-input" placeholder="请填写您的手机号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    身份证号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.id_card" class="rounded-input" placeholder="请填写您的身份证号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    联系方式:
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.contactID" name="radioGroup" @change="contactWay(1)" :default-value="1">
                      <a-radio :value="1" class="custom-radio">
                        微信号
                      </a-radio>
                      <a-radio :value="2" style="margin-left: 50px;" class="custom-radio">
                        其他
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-input v-model="contactPlatform" :disabled="form.contactID == 1" class="rounded-input"
                      style="width:258px" placeholder="请填写您的联系账号平台" />
                  </a-col>
                </a-row>
                <a-row style="margin-top:20px">
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <input v-model="form.contact" class="rounded-input" placeholder="请填写您的联系账号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    常用邮箱:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.email" class="rounded-input" placeholder="请填写您的常用邮箱" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="10" class="type">
                    <div class="agreement">
                      <div class="checkBox" @click="agree">
                        <a-icon v-show="iconShow" type="check" style="color:#09bb07" />
                      </div>
                      <div class="agreementText">
                        <div class="text">我已阅读并同意</div>
                        <div class="link">
                          <a class="aStyle" @click="agreenment">《集运系统入驻协议》</a>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item" v-if="facePass == 1">
                <a-row>
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <a-button @click="submit(1)" :loading="btnLoading" class="confirm-btn">确认提交</a-button>
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item" v-else>
                <a-row>
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <a-button @click="next" class="confirm-btn">下一步</a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <div v-show="form.p_country_ids == 2">
            <div class="proposer-info">
              <h4 class="info-title">a.申请人信息</h4>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    姓名:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.name" class="rounded-input" placeholder="请填写您的姓名" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    手机号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.phone" class="rounded-input" placeholder="请填写您的手机号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    联系方式:
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.contactID" name="radioGroup" @change="contactWay(1)" :default-value="1">
                      <a-radio :value="1" class="custom-radio">
                        微信号
                      </a-radio>
                      <a-radio :value="2" style="margin-left: 50px;" class="custom-radio">
                        其他
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-input v-model="contactPlatform" :disabled="form.contactID == 1" class="rounded-input"
                      style="width:258px" placeholder="请填写您的联系账号平台" />
                  </a-col>
                </a-row>
                <a-row style="margin-top:20px">
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <input v-model="form.contact" class="rounded-input" placeholder="请填写您的联系账号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    常用邮箱:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.email" class="rounded-input" placeholder="请填写您的常用邮箱" />
                  </a-col>
                </a-row>
              </div>

            </div>
            <div class="proposer-info">
              <h4 class="info-title">b.联系人信息</h4>
              <h6 class="notice-title">注：因申请人为海外国籍，故需要一名中国国籍的备用联系人</h6>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    姓名:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.p_cif_name" class="rounded-input" placeholder="请填写备用联系人姓名" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    手机号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.p_cif_phone" class="rounded-input" placeholder="请填写备用联系人手机号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    身份证号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.id_card" class="rounded-input" placeholder="请填写备用联系人身份证号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    联系方式:
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.p_cif_contactID" @change="contactWay(2)" name="radioGroup"
                      :default-value="1">
                      <a-radio :value="1" class="custom-radio">
                        微信号
                      </a-radio>
                      <a-radio :value="2" style="margin-left: 50px;" class="custom-radio">
                        其他
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-input v-model="p_cif_contactPlatform" :disabled="form.p_cif_contactID == 1" class="rounded-input"
                      style="width:258px" placeholder="请填写备用联系人账号平台" />
                  </a-col>
                </a-row>
                <a-row style="margin-top:20px">
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <input v-model="form.p_cif_contact" class="rounded-input" placeholder="请填写备用联系人账号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    常用邮箱:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.p_cif_email" class="rounded-input" placeholder="请填写备用联系人常用邮箱" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="10" class="type">
                    <div class="agreement">
                      <div class="checkBox" @click="agree">
                        <a-icon v-show="iconShow" type="check" style="color:#09bb07" />
                      </div>
                      <div class="agreementText">
                        <div class="text">我已阅读并同意</div>
                        <div class="link">
                          <a class="aStyle" @click="agreenment">《集运系统入驻协议》</a>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <a-button @click="submit(2)" :loading="btnLoading" class="confirm-btn">确认提交</a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="form.exa_type == 2">
        <div v-show="form.c_reg == 1" class="entry-item">
          <h4 class="title">02.请填写联系方式,&nbsp;&nbsp;以便工作人员联系您沟通相关事宜</h4>
          <div class="entryType">
            <div class="proposer-info">
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    姓名:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.name" class="rounded-input" placeholder="请填写您的姓名" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    手机号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.phone" class="rounded-input" placeholder="请填写您的手机号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    身份证号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.id_card" class="rounded-input" placeholder="请填写您的身份证号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    联系方式:
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.contactID" name="radioGroup" @change="contactWay(1)" :default-value="1">
                      <a-radio :value="1" class="custom-radio">
                        微信号
                      </a-radio>
                      <a-radio :value="2" style="margin-left: 50px;" class="custom-radio">
                        其他
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-input v-model="contactPlatform" :disabled="form.contactID == 1" class="rounded-input"
                      style="width:258px" placeholder="请填写您的联系账号平台" />
                  </a-col>
                </a-row>
                <a-row style="margin-top:20px">
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <input v-model="form.contact" class="rounded-input" placeholder="请填写您的联系账号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    常用邮箱:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.email" class="rounded-input" placeholder="请填写您的常用邮箱" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <a-button @click="submit(3)" :loading="btnLoading" class="confirm-btn">提交</a-button>
                  </a-col>
                </a-row>
              </div>

            </div>
          </div>
        </div>
        <div v-show="form.c_reg == 2" class="entry-item">
          <h4 class="title">02.请填写联系方式,&nbsp;&nbsp;以便工作人员联系您沟通相关事宜</h4>
          <div class="entryType">
            <div class="proposer-info">
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    公司名称:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.c_name" class="rounded-input" placeholder="请填写公司名称" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    经营地址:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.c_addr" class="rounded-input" placeholder="请填写公司经营地址" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    统一社会信用代码:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.c_code" class="rounded-input" placeholder="请填写公司经营类型" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    营业执照:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                      action="" :before-upload="beforeUpload" @change="handleChange">
                      <img class="img" v-if="form.c_image.length > 0" :src="form.c_image" />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                      </div>
                    </a-upload>
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    法人姓名:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.name" class="rounded-input" placeholder="请填写您的姓名" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    法人手机号:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.phone" class="rounded-input" placeholder="请填写您的手机号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    联系方式:
                  </a-col>
                  <a-col :span="6">
                    <a-radio-group v-model="form.contactID" name="radioGroup" @change="contactWay(1)" :default-value="1">
                      <a-radio :value="1" class="custom-radio">
                        微信号
                      </a-radio>
                      <a-radio :value="2" style="margin-left: 50px;" class="custom-radio">
                        其他
                      </a-radio>
                    </a-radio-group>
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <a-input v-model="contactPlatform" :disabled="form.contactID == 1" class="rounded-input"
                      style="width:258px" placeholder="请填写您的联系账号平台" />
                  </a-col>
                </a-row>
                <a-row style="margin-top:20px">
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <input v-model="form.contact" class="rounded-input" placeholder="请填写您的联系账号" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                    常用邮箱:
                  </a-col>
                  <a-col :span="4" class="selStyle">
                    <input v-model="form.email" class="rounded-input" placeholder="请填写您的常用邮箱" />
                  </a-col>
                </a-row>
              </div>
              <div class="proposer-item">
                <a-row>
                  <a-col :span="4" class="type">
                  </a-col>
                  <a-col :span="4">
                    <a-button @click="submit(4)" :loading="btnLoading" class="confirm-btn">确认提交</a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="dialogVisible" class="dialog-overlay">
        <div class="dialog-container">
          <div class="topImg">
            <img class="bgImg" src="@/assets/smartcat/popUpBg.png" alt="">
          </div>

          <div class="contact">
            <img class="contactImg" v-if="qr_code" :src="qr_code" alt="">
            <a-icon type="loading" v-else :style="{ fontSize: '30px' }" />
          </div>
          <div>
            <p class="text">请前往小程序完成人脸识别验证</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button class="botBtn" @click="closeDialog">X</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
function getBase64 (img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {

  data () {
    return {
      facePass: '',
      dialogVisible: false,
      qr_code: '',
      timer: '', //轮训定时器
      submitBtn: true,
      skeleton: true,
      loading: false,//上传
      btnLoading: false, //提交按钮--fkp
      contactPlatform: '',//联系平台
      p_cif_contactPlatform: '',//联系人b联系平台
      form: {
        id: '',
        id_card: '',
        exa_status: '',//审核状态
        exa_type: 1, //个人公司
        name: '',
        phone: '',
        email: '',
        contact: '',
        contactShow: '',
        country: {
          id: '',
          cn_name: ''
        },
        contactID: 1,//radio联系平台默认微信
        p_cif_contactID: 1,//联系人b联系默认微信
        p_country_id: undefined,//国家
        p_country_ids: 1,
        p_cif_name: '',
        p_cif_phone: '',
        p_cif_email: '',
        p_cif_contact: '',
        p_cif_contactShow: '',
        c_reg: 1,//是否需要代办
        c_name: '',
        c_addr: '',
        c_code: '',
        c_image: [],
        remake: '',
      },
      id: '',
      form_id: '',
      china_id: '',
      imageUrl: '',
      radioStyle: {
        display: 'block',
        height: '40px',
        lineHeight: '30px',
      },
      radioValue: 1,
      country: [
        {
          value: '',
          label: ''
        },
      ],
      iconShow: false

    }
  },
  created () {
    this.getCountry()
    this.id = this.$store.state.id
  },

  methods: {
    input (e) {
      console.log(e.target.value);
      this.form.contactPlatform = e.target.value
      this.$set(this.form, 'contactPlatform', e.target.value)
    },
    //获取审核状态
    getAudit () {
      this.$request.getAudit(this.id).then(res => {
        if (res.ret) {
          // exa_type=1个人 2 公司
          // p_country_id 测试服1153中国 其他为外籍
          // c_reg 1需要注册2 不需要注册
          this.skeleton = false
          if (res.data.length > 0) {
            this.form = res.data[0]
            if (this.form.contact.split(':')[0].length > 0) {
              this.form.contactShow = this.form.contact
              this.contactPlatform = this.form.contact.split(':')[0]
              this.form.contact = this.form.contact.split(':')[1]
              this.$set(this.form, 'contactID', 2)
            } else {
              this.form.contactShow = '微信号' + this.form.contact
              this.contactPlatform = this.form.contact.split(':')[0]
              this.form.contact = this.form.contact.split(':')[1]
              this.$set(this.form, 'contactID', 1)
            }
            if (this.form.p_cif_contact.split(':')[0].length > 0) {
              this.form.p_cif_contactShow = this.form.p_cif_contact
              this.p_cif_contactPlatform = this.form.p_cif_contact.split(':')[0]
              this.form.p_cif_contact = this.form.p_cif_contact.split(':')[1]
              this.$set(this.form, 'p_cif_contactID', 2)
            } else {
              this.form.p_cif_contactShow = '微信号' + this.form.p_cif_contact
              this.p_cif_contactPlatform = this.form.p_cif_contact.split(':')[0]
              this.form.p_cif_contact = this.form.p_cif_contact.split(':')[1]
              this.$set(this.form, 'p_cif_contactID', 1)
            }
            if (this.form.p_country_id == this.china_id) {
              this.$set(this.form, 'p_country_ids', 1)
              this.form.p_country_id = undefined
            } else {
              this.$set(this.form, 'p_country_ids', 2)
            }
            if (this.form.exa_type == 1 && this.form.country.id == this.china_id) {
              this.form_id = 1
            } else if (this.form.exa_type == 1 && this.form.country.id !== this.china_id) {
              this.form_id = 2
            } else if (this.form.exa_type == 2 && this.form.c_reg == 1) {
              this.form_id = 3
            } else if (this.form.exa_type == 2 && this.form.c_reg == 2) {
              this.form_id = 4
            }
          } else {
            this.form.exa_status = 99
          }
        }
      })
    },
    //国家地区--fkp
    getCountry () {
      this.$request.country().then(res => {
        if (res.ret) {
          let newCountry = []
          res.data.forEach(item => {
            if (item.en_name.toUpperCase() == 'CHINA') {
              this.china_id = item.id
            }
            if (item.id !== this.china_id) {
              newCountry.push(item)
            }
            this.country = newCountry
          })
          this.getAudit()
        }
      })
    },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    //联系方式切换--fkp
    contactWay (id) {
      if (id == 1) {
        this.$set(this, 'contactPlatform', '')
      } else {
        this.$set(this, 'p_cif_contactPlatform', '')
      }
    },
    countryWay () {
      this.$set(this.form, 'p_country_id', undefined)
    },
    //提交
    submit (type) {
      if (this.form.name === '') {
        return this.$message.error('请填写您的联系姓名')
      } else if (this.form.phone === '') {
        return this.$message.error('请填写您的手机号')
      } else if (this.form.email === '') {
        return this.$message.error('请填写您的邮箱')
      } else if (this.form.contact === '') {
        return this.$message.error('请填写您的联系账号')
      } else if (this.form.contactID == 2) {
        if (this.contactPlatform === '') {
          return this.$message.error('请填写您的联系账号平台')
        }
      }
      if (type === 1) {
        if (!this.form.id_card) {
          return this.$message.error('请填写身份证号')
        } else if (this.submitBtn) {
          return this.$message.error('请勾选阅读并同意协议')
        }
        this.form.p_country_id = this.china_id
        this.btnLoading = true
        let params = {
          exa_type: this.form.exa_type,
          p_country_id: this.form.p_country_id,
          name: this.form.name,
          phone: this.form.phone,
          contact: this.contactPlatform + ":" + this.form.contact,
          email: this.form.email,
          id_card: this.form.id_card
        }
        this.$request.subAudit(this.id, params).then(res => {
          if (res.ret) {
            this.$message.success('提交成功')
            this.btnLoading = false
            this.form.exa_status = 1
            this.facePass = ''
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if (type === 2) {
        if (!this.form.id_card) {
          return this.$message.error('请填写身份证号')
        }
        //个人外国国籍
        if (this.form.p_country_id === undefined) {
          return this.$message.error('请选择国籍')
        } else if (this.form.p_cif_name === '') {
          return this.$message.error('请填写备用联系人姓名')
        } else if (this.form.p_cif_phone === '') {
          return this.$message.error('请填写备用联系人手机号')
        } else if (this.form.p_cif_email === '') {
          return this.$message.error('请填写备用联系人邮箱')
        } else if (this.form.p_cif_contactID == 2) {
          if (this.p_cif_contactPlatform === '') {
            return this.$message.error('请填写备用联系人账号平台')
          }
        } else if (this.submitBtn) {
          return this.$message.error('请勾选阅读并同意协议')
        }
        if (this.form.p_cif_contact == undefined) {
          return this.$message.error('请填写备用联系人联系账号')
        }

        this.btnLoading = true
        let params = {
          exa_type: this.form.exa_type,
          p_country_id: this.form.p_country_id,
          name: this.form.name,
          phone: this.form.phone,
          contact: this.contactPlatform + ':' + this.form.contact,
          email: this.form.email,
          p_cif_name: this.form.p_cif_name,
          p_cif_phone: this.form.p_cif_phone,
          p_cif_contact: this.p_cif_contactPlatform + ':' + this.form.p_cif_contact,
          p_cif_email: this.form.p_cif_email,
          id_card: this.form.id_card
        }
        this.$request.subAudit(this.id, params).then(res => {
          if (res.ret) {
            this.$message.success('提交成功')
            this.btnLoading = false
            this.form.exa_status = 1
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if (type == 3) {
        //公司授权办理--fkp
        if (!this.form.id_card) {
          return this.$message.error('请填写身份证号')
        } 
        this.btnLoading = true
        let params = {
          exa_type: this.form.exa_type,
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
          contact: this.contactPlatform + ':' + this.form.contact,
          c_reg: this.form.c_reg,
          id_card: this.form.id_card
        }
        if (this.form.id_card == '') {
          return this.$message.error('请填写身份证号')
        }
        this.$request.subAudit(this.id, params).then(res => {
          if (res.ret) {
            this.$message.success('提交成功')
            this.btnLoading = false
            this.form.exa_status = 1
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if (type == 4) {
        if (this.form.c_name === '') {
          return this.$message.error('请填写公司名称')
        } else if (this.form.c_addr === '') {
          return this.$message.error('请填写经营地址')
        } else if (this.form.c_code === '') {
          return this.$message.error('请填写统一社会信用代码')
        } else if (this.form.c_image == '') {
          return this.$message.error('请上传营业执照')
        } else if (this.form.p_cif_contactID == 2) {
          if (this.p_cif_contactPlatform === '') {
            return this.$message.error('请填写备用联系人账号平台')
          }
        }
        this.btnLoading = true
        let params = {
          exa_type: this.form.exa_type,
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
          contact: this.contactPlatform + ':' + this.form.contact,
          c_name: this.form.c_name,
          c_addr: this.form.c_addr,
          c_code: this.form.c_code,
          c_image: this.form.c_image,
          c_reg: this.form.c_reg,
        }
        this.$request.subAudit(this.id, params).then(res => {
          if (res.ret) {
            this.$message.success('提交成功')
            this.btnLoading = false
            this.form.exa_status = 1
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    beforeUpload (file) {
      this.onUpload(file).then(res => {
        console.log(res);
        if (res.ret) {
          this.form.c_image = this.$baseUrl.IMAGE_URL +  res.data[0].path
          this.$message.success('上传成功')
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    // 上传图片
    onUpload (file) {
      let params = new FormData()
      params.append(`images[${0}][file]`, file)
      return this.$request.uploadImg(params)
    },
    copy () {
      navigator.clipboard.writeText(this.form.fch_url)
      this.$message.success('复制成功')
    },
    re_edit (type) {
      this.form.exa_status = 99
      this.form_id = ''
      if (type == 1) {
        console.log(1);
      } else if (type == 2) {
        console.log(2);
      } else if (type == 3) {
        console.log(3);
      } else if (type == 4) {
        console.log(4);
      }
    },
    agree () {
      this.submitBtn = false
      this.iconShow = !this.iconShow
    },
    agreenment () {
      let url = `https://beego-1252088954.cos.ap-hongkong.myqcloud.com/smartcatplus_protocol.pdf`;
      window.open(url, "_blank");
      // this.$router.push({
      //   name:'agreement'
      // })
    },
    next () {
      this.$request.faceImg(this.id).then(res => {
        if (res.ret) {
          this.qr_code = res.data.image
          this.setTimer()
        }
      })
      this.dialogVisible = true
    },
    closeDialog () {
      this.dialogVisible = false
      clearTimeout(this.timer)
    },
    /*轮询*/
    setTimer () {
      this.$request.faceStatus(this.id).then(res => {
        if (res.ret) {
          console.log(res.data);
          this.timer = setTimeout(() => {
            this.setTimer()
          }, 2000)
          if (res.data == 1) {
            this.facePass = 1
            this.$message.success('人脸核实通过')
            this.closeDialog ()
            this.dialogVisible = false
          } else if (res.data == 2) {
            this.$message.error('人脸核实失败请刷新重试')
            clearTimeout(this.timer)
          }
        } else {
          clearTimeout(this.timer) //清理定时任务
        }
      })
    },
    openStore () {
      this.$request.fchOpen(this.form.id).then(res => {
        if (res.ret) {
          this.$message.success('开通成功')
          this.getAudit()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }

}
</script>
  
<style lang="scss" scoped>
/deep/.avatar-uploader>.ant-upload {
  width: 80px;
  height: 80px;
}

/deep/.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

/deep/.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/deep/.custom-radio:hover .ant-radio-inner {
  border-color: #ffd000;
}

/deep/.custom-radio:hover .ant-radio-inner::after {
  background-color: #ffd000;
}

/deep/.ant-radio-checked .ant-radio-inner {
  border-color: #ffd000;
  background: #ffd000;
}

/deep/.ant-radio-inner::after {
  background-color: #fff;
}

/deep/.rounded-select .ant-select-selection--single {
  width: 256px;
  height: 34px;
  padding-top: 2px;
  border-radius: 20px;
  background-color: #f0f0f0;
  border: none;

}


/deep/.ant-select-selection__placeholder {
  margin-left: 5px;
  color: #757575;
}


/deep/.selStyle .ant-select-selection {
  border: none !important;
  box-shadow: none;
}

/deep/.selStyle .ant-select-selection--single {
  width: 250px;
  height: 36px;
  border-radius: 20px;
  background-color: #f0f0f0;
  padding-top: 2px;
  border-right: 0;
  padding-left: 5px;
}

/deep/.ant-input:hover,
input:focus {
  border: 0;
  box-shadow: 0 0 0 0px #f3f3f3 inset;
}

.ml50 {
  margin-left: 50px;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    background-color: #fff;
    border-radius: 12px;
    background: linear-gradient(to top, #fff, #fff5cb);

    .topImg {
      margin-top: -70px;

      .bgImg {
        width: 268px;
        height: 126px;
      }
    }

    .contact {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 160px;
      background-color: #fff5c9;
      border-radius: 15px;
      margin-top: 40px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      .contactImg {
        padding: 4px;
        background-color: #fff;
        width: 140px;
        height: 140px;
        border-radius: 8px;
      }
    }

    .text {
      margin-top: 30px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 25.2px;
      color: rgba(102, 102, 102, 1);
    }
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;

  .botBtn {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }
}

.rounded-input {
  width: 480px;
  height: 36px;
  border-radius: 20px;
  border-width: 0px;
  padding-left: 20px;
  background-color: #f0f0f0;
  border-color: none;
  outline: none;
}

.entryMaterials-container {
  background-color: #fff;

  .waitLoading {
    height: 220PX;

    .loading,
    .loading>div {
      position: relative;
      box-sizing: border-box;
    }

    .loading {
      display: block;
      margin: auto;
      margin-top: 100px;
      font-size: 0;
      color: #000;
    }

    .loading.la-dark {
      color: #333;
    }

    .loading>div {
      display: inline-block;
      float: none;
      background-color: currentColor;
      border: 0 solid currentColor;
    }

    .loading {
      width: 16px;
      height: 16px;
    }

    .loading>div {
      position: absolute;
      top: 0;
      left: -100%;
      display: block;
      width: 16px;
      width: 100%;
      height: 16px;
      height: 100%;
      border-radius: 100%;
      opacity: 0.5;
      animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1),
        ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
    }

    .loading>div:nth-child(1) {
      animation-delay: 0s, -0.5s;
    }

    .loading>div:nth-child(2) {
      animation-delay: -0.5s, -1s;
    }

    .loading>div:nth-child(3) {
      animation-delay: -1s, -1.5s;
    }

    .loading>div:nth-child(4) {
      animation-delay: -1.5s, -2s;
    }

    .loading>div:nth-child(5) {
      animation-delay: -2s, -2.5s;
    }

    @keyframes ball-circus-position {
      50% {
        left: 100%;
      }
    }

    @keyframes ball-circus-size {
      50% {
        transform: scale(0.3, 0.3);
      }
    }
  }

  .waiting {
    margin-top: 100px;
    text-align: center;
    height: 520px;

    .address {
      margin-top: 60px;

      .addr-item {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .text {
          .span {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .addr {
          margin-left: 10px;
        }

        .copyBtn {
          margin-left: 15px;

          .btn {
            background: #ffd000;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }

        }

        .pic {
          .img {
            width: 150px;
            height: 150px;
          }
        }
      }


    }

    .pic {
      margin-top: 30px;

      .img {
        width: 200px;
        height: 200px;
        opacity: 1;
        background: rgba(255, 255, 255, 1);
      }
    }

    .title_blod {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 22.4px;
      color: rgba(34, 34, 34, 1);
    }

    .title {
      .span {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(136, 136, 136, 1);
      }
    }

    .btn {
      .confirm-btn {
        width: 240px;
        height: 36px;
        opacity: 1;
        border-radius: 18px;
        border-width: 0;
        background: rgba(255, 208, 0, 1);
        cursor: pointer;
        color: #000;
      }

      .confirm-btn:hover {
        border-color: #000 !important;
        color: #fff;
        background: #000;
      }
    }
  }

  .pass {
    margin-top: 10px;
    height: 520px;

    .top {
      display: flex;
      margin-top: 30px;
      margin-left: 30px;
      align-items: center;

      .pic {
        margin-right: 10px;

        .img {
          width: 30px;
          height: 30px;
        }
      }

      .title {
        .span {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }

    .address {
      margin-top: 60px;

      .addr-item {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .text {
          .span {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .addr {
          margin-left: 10px;
        }

        .copyBtn {
          margin-left: 15px;

          .btn {
            background: #ffd000;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }

        }

        .pic {
          .img {
            width: 150px;
            height: 150px;
          }
        }
      }


    }

    .Addr {
      .addr-item {
        display: flex;
        margin-left: 30px;
        margin-top: 20px;

        .text {
          .span {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .addr {
          margin-left: 10px;
        }

        .copyBtn {
          margin-left: 15px;

          .btn {
            background: #ffd000;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }

        }

        .pic {
          margin-top: -20px;

          .img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }

  .fail {
    margin-top: 10px;
    margin-bottom: 100px;

    .address {
      margin-top: 60px;

      .addr-item {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .text {
          .span {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .addr {
          margin-left: 10px;
        }

        .copyBtn {
          margin-left: 15px;

          .btn {
            background: #ffd000;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }

        }

        .pic {
          .img {
            width: 150px;
            height: 150px;
          }
        }
      }


    }

    .top {
      display: flex;
      margin-top: 30px;
      margin-left: 30px;
      align-items: center;

      .pic {
        margin-right: 10px;

        .img {
          width: 30px;
          height: 30px;
        }
      }

      .titleSpan {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .refusalReason {
      display: flex;
      margin-top: 30px;
      margin-left: 30px;

      .left {
        width: 83px;
        height: 23px;

        .span {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(68, 68, 68, 1);
        }
      }

      .right {
        width: 790px;

        .span {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(68, 68, 68, 1);
        }
      }
    }

    .question {
      margin-top: 30px;
      margin-left: 30px;

      .span {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(136, 136, 136, 1);
      }
    }

    .title {
      margin-top: 30px;
      margin-left: 30px;

      .submitted {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 22.4px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .submittedForm {
      margin-top: 10px;
      margin-left: 30px;

      .formItem {
        display: flex;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22.4px;
        color: rgba(68, 68, 68, 1);

        .applyInfo {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(0, 0, 0, 1) !important;
        }

        .businessLicense {
          .img {
            width: 80px;
            height: 80px;
            background-color: pink;
          }
        }

        .btn {
          margin-top: 30px;
          width: 240px;
          height: 36px;
          opacity: 1;
          border-radius: 18px;
          background: rgba(255, 208, 0, 1);
          border: none;
          cursor: pointer;
          color: #000;
        }

        .btn:hover {
          border-color: #000 !important;
          color: #fff;
          background: #000;
        }
      }
    }
  }

  .submit {
    .entry-item {
      margin: 38px 0px 53px 30px;

      .title {
        height: 23px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22.4px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
      }

      .entryType {
        width: 100%;
        margin-top: 53px;

        .type {
          height: 23px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 22.4px;
          color: rgba(68, 68, 68, 1);
          text-align: left;
          vertical-align: top;
        }

        .selStyle {
          margin-top: -10px;

          .avatar-uploader {
            width: 100px;
            height: 100px;
          }

          .img {
            width: 80px;
            height: 80px;
          }
        }

        .proposer-info {
          margin-top: 30px;

          .info-title {
            height: 23px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 30px;
          }

          .notice-title {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 19.6px;
            color: rgba(136, 136, 136, 1);
            margin-bottom: 30px;
          }

          .proposer-item {
            margin-bottom: 40px;

            .confirm-btn {
              width: 240px;
              height: 36px;
              opacity: 1;
              border-radius: 18px;
              border-width: 0;
              background: rgba(255, 208, 0, 1);
              cursor: pointer;
              color: #000;
            }

            .confirm-btn:hover {
              border-color: #000 !important;
              color: #fff;
              background: #000;
            }

            .type {
              height: 23px;
              font-size: 16px;
              line-height: 22.4px;
              color: rgba(68, 68, 68, 1);

              .agreement {
                display: flex;

                .checkBox {
                  width: 20px;
                  height: 20px;
                  opacity: 1;
                  border-radius: 5px;
                  border: 1px solid rgba(204, 204, 204, 1);
                  cursor: pointer;
                }

                .agreementText {
                  display: flex;
                  margin-left: 10px;

                  .text {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 22.4px;
                    color: rgba(34, 34, 34, 1);

                  }

                  .link {
                    .aStyle {
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 0px;
                      line-height: 22.4px;
                      color: rgba(255, 141, 26, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

}</style>
<style>ul>.ant-select-dropdown-menu-item-active {
  background: #ffd000 !important;
}</style>
